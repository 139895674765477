.add-scene-btn {
  cursor: pointer;
}

.add-scene-modal {
  @apply fixed z-10 inset-0 overflow-y-auto;

  .dialog-panel {
    @apply relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6
      dark:bg-dark-mode-blue-dark;

    input[type='checkbox'] {
      @apply focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded;
    }
  }

  .modal-content {
    @apply flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0
      dark:border-dark-mode-blue-medium;
  }
}
