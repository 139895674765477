.details__container {
  .details__header {
    @apply font-semibold px-3 py-3 flex flex-row justify-between align-baseline border-b
      duration-300 dark:bg-base-200 dark:border-dark-mode-blue-medium;
  }

  .details__content {
    overflow: scroll; //temp

    @apply bg-white h-screen p-4
      duration-300 dark:bg-base-300;

    .section {
      @apply mt-8;

      h2.title {
        @apply font-semibold py-1 border-b;
      }
    }
  }
}
