@import './src/assets/styles/abstracts/_mixins.scss';

div.dashboard {
  @apply grid h-screen place-items-center;

  &__content {
    @apply rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-3 sm:gap-px;

    .workout-icon {
      @apply rounded-lg inline-flex p-3 rounded-lg inline-flex p-3 rounded-lg inline-flex p-3 ring-4 ring-white;

      &.create {
        @apply bg-teal-100 text-teal-400;
      }
      &.configure {
        @apply bg-purple-100 text-purple-400;
      }
      &.load {
        @apply bg-sky-100 text-sky-400;
      }
    }

    span {
      &.arrow-out {
        @apply pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400;
      }
    }

    p {
      @apply mt-2 text-sm text-gray-500;
    }
  }

  &__card {
    @include underline-hover(#7187f4, #545de1);
    opacity: 0.65;

    &:hover {
      opacity: 1;
    }
  }
}
