@import './src/assets/styles/abstracts/_mixins.scss';

body,
html {
  @apply bg-f45-grey-100 text-f45-grey-500
    dark:text-neutral-content;
}

.dashboard__container {
  @apply flex flex-wrap bg-gray-50 h-screen border-t
    duration-300 dark:bg-dark-mode-blue-dark dark:border-dark-mode-blue-medium;

  .column {
    &__left {
      @apply basis-full sm:basis-2/12 border-b sm:border-b-0 border-gray-200 border-r bg-gray-100
        duration-300 dark:bg-base-300 dark:border-dark-mode-blue-medium;
    }

    &__center {
      @apply basis-full sm:basis-7/12 border-b sm:border-b-0 border-gray-200
        duration-300 dark:bg-base-300;
    }

    &__right {
      @apply basis-full sm:basis-3/12 border-b sm:border-b-0 border-gray-200 border-l bg-gray-100
        duration-300 dark:bg-base-300 dark:border-dark-mode-blue-medium;
    }
  }
}
