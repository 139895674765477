@tailwind base;
@tailwind components;
@tailwind utilities;

/**
  Monster Rat font
     ) _     _
    ( (^)-~-(^)
__,-.\_( 6 6 )__,-.___
  'M'   \   /   'M'
         >o<
 **/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,500;0,600;0,700;1,100;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,400;0,500;0,600;0,700;1,100;1,400;1,500;1,600;1,700&display=swap');

@import 'inputs';
@import 'typography';
@import './abstracts/mixins';
@import 'animate.css';

body,
html {
  @apply bg-f45-blue-900 text-black dark:bg-dark-mode-blue-dark;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  @apply text-f45-blue-600;
}
