@mixin underline-hover($color, $hover: '') {
  cursor: pointer;

  a {
    display: inline-block;
    position: relative;
    color: $color;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $color;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover {
      color: $hover;

      &:after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}
