.preview__container {
  .preview__header {
    @apply font-semibold px-3 py-3 flex justify-between align-baseline border-b
      duration-300 dark:bg-base-200 dark:border-dark-mode-blue-medium;
  }

  .preview__content {
    @apply bg-white h-screen p-4
      duration-300 dark:bg-base-300;
  }
}
