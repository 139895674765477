div#login-panel {
  @apply text-gray-300;
  background: url('/assets/images/backgrounds/workout.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

div#overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.35);
  z-index: 1;

  div#login-container {
    @apply min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8;
    z-index: 9;

    & img {
      &.logo {
        @apply mx-auto h-12 w-auto;
        height: 2.45em;
      }
    }

    h2 {
      @apply text-f45-blue-900 text-3xl font-extrabold text-center p-4;
    }

    div {
      &.socials {
        a {
          @apply w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md
          shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50;

          &:hover {
            @apply text-f45-red;
          }
        }
      }
    }
  }
}
