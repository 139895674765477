.menu {
  &.btn {
    @apply bg-white rounded-full flex text-sm focus:outline-none
      dark:bg-transparent dark:bg-dark-mode-blue-medium;

    div.user-menu {
      @apply grid gap-6 grid-cols-2 text-f45-grey-500 hover:text-f45-grey-900
        dark:bg-transparent dark:bg-dark-mode-blue-medium dark:hover:text-dark-mode-blue-light;
    }
  }
}

a.notifications {
  @apply bg-white p-2 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
  dark:bg-transparent dark:text-primary dark:hover:text-dark-mode-blue-light;
  //dark:bg-transparent dark:bg-dark-mode-blue-medium dark:hover:text-dark-mode-blue-light;
}

.nav-link {
  @apply text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium hover
  dark:text-primary dark:border-dark-mode-blue-medium;
}

nav {
  @apply bg-white
    dark:bg-base-200;

  a.hover {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: #7187f4;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover {
      &:after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
}
