input[type='text'],
input[type='password'],
input[type='email'] {
  @apply appearance-none block w-full px-3 py-2 text-gray-700
    border border-gray-300 rounded-md shadow-sm placeholder-gray-400
    focus:outline-none focus:ring-f45-blue-500 focus:border-f45-blue-500 sm:text-sm
    dark:bg-dark-mode-blue-darker dark:border-dark-mode-blue-medium-light;

  &.no-round-borders {
    border-radius: 0 !important;
  }

  &::placeholder {
    @apply text-gray-500;
  }
}

input {
  &.checkbox {
    @apply h-4 w-4 text-f45-red focus:ring-f45-red border-gray-300 rounded;
  }
}

select {
  @apply py-1 px-2 shadow-sm focus:ring-f45-blue-500 focus:border-f45-blue-500 sm:text-sm border border-gray-300 rounded-sm
    dark:bg-dark-mode-blue-darker dark:border-dark-mode-blue-medium-light;
}

label {
  @apply block text-sm font-medium text-gray-700 pb-1;
}

button {
  &:not(.toggle) {
    @apply w-full flex justify-center py-2 px-4 border border-transparent rounded-md
      shadow-sm text-sm font-medium text-white focus:outline-none;
  }

  .toggle {
    @apply bg-f45-blue-600 dark:bg-f45-grey-600;
  }

  &.default {
    @apply text-white bg-f45-blue-600 hover:bg-f45-blue-700
      dark:bg-primary-focus font-medium dark:text-black dark:hover:bg-primary;
  }

  &.red {
    @apply text-white bg-f45-red;
  }

  &.shaded {
    @apply text-black bg-f45-grey-300 w-1/2;
  }
}
