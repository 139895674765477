.scene__container {
  .scene__header {
    @apply font-semibold px-3 py-3 border-b
      duration-300 dark:bg-base-200 dark:border-dark-mode-blue-medium;
  }

  .scene__content {
    @apply bg-white h-screen py-4
      dark:bg-base-300;

    .menu__item {
      @apply py-2 px-4 cursor-pointer flex justify-between hover:bg-gray-300;

      &--sub {
        @apply cursor-pointer flex py-2 hover:bg-gray-200;

        &.active {
          @apply bg-gray-200;
        }
      }
    }
  }
}
